import catalog from "../ducks/catalog";

import { FETCH_CATALOG, FETCH_LOCATIONS } from "../queries/my-queries";
import location from "../ducks/location";
import client from "../graphql-client";
import store from "../store";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../ducks";
import _ from "lodash";
import cart from "../ducks/cart";

const useLoadData = () => {
  const dispatch = useDispatch();
  const { lineItems } = useSelector((state: RootState) => state.cart);

  const checkCart = () => {
    const state = store.getState();
    const { items } = state.catalog.data;

    const lineItemJooIds = lineItems.map(({ item_id }) => item_id);
    const catalogItemJooIds = _.map(items, ({ id }) => id);

    const intersectionIds = _.intersection(lineItemJooIds, catalogItemJooIds);

    const difference = _.difference(lineItemJooIds, intersectionIds);

    if (difference.length > 0) {
      dispatch(cart.actions.removeMultipleByJooId({ jooIds: difference }));
    }
  };

  const loadData = () => {
    client
      .query({
        query: FETCH_CATALOG,
      })
      .then((result) => {
        let data, error;
        if (result) {
          data = result.data;
          error = result.error;
        } else {
          error = "Bad result";
        }
        store.dispatch(catalog.actions.load({ data, error }));
        // Check that items in the cart are still valid
        checkCart();
      })
      .catch((error) => store.dispatch(catalog.actions.load({ error })));

    client
      .query({
        query: FETCH_LOCATIONS,
      })
      .then((result) => {
        let data, error;
        if (result) {
          data = result.data;
          error = result.error;
        } else {
          error = "Bad result";
        }
        store.dispatch(location.actions.load({ data, error }));
        // Check that selected location is still valid
      })
      .catch((error) => store.dispatch(location.actions.load({ error })));
  };
  return loadData;
};

export default useLoadData;
