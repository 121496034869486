import { useDispatch } from "react-redux";
import user from "../ducks/user";
import ui from "../ducks/ui";
import { useHistory } from "react-router-dom";
import { ACCOUNT_PAGE_PATH } from "../router";
import queryString from "query-string";
import url from "url";

const useLogin = (redirect?: string) => {
  const dispatch = useDispatch();
  const history = useHistory();

  const showLogin = () => {
    dispatch(user.actions.stopBeingGuest());
    dispatch(user.actions.updatingEditing(false));
    dispatch(ui.actions.setAccountAccessView(undefined));

    const newUrl = createUrl({
      base: ACCOUNT_PAGE_PATH,
      currentSearch: history.location.search,
      currentPath: history.location.pathname,
      redirect,
    });

    history.push(newUrl);
  };

  const handleLoginRedirect = () => {
    const { redirect } = queryString.parse(history.location.search);
    if (redirect && typeof redirect === "string") {
      const { pathname, search } = url.parse(redirect);
      history.push(`${pathname}${search ? search : ""}`);
    }
  };

  return { showLogin, handleLoginRedirect };
};

const createUrl = ({
  base,
  currentSearch,
  currentPath,
  redirect,
}: {
  base: string;
  currentSearch: string;
  currentPath: string;
  redirect?: string;
}) => {
  const determinedRedirect = redirect
    ? redirect
    : `${currentPath}${currentSearch}`;

  return queryString.stringifyUrl({
    url: base,
    query: { redirect: determinedRedirect },
  });
};

export default useLogin;
