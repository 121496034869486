import React, { FC, useEffect } from "react";
import "./App.less";
import AppRouter from "./router";
import useLoadData from "./hooks/useLoadData";

const App: FC = () => {
  const loadData = useLoadData();
  useEffect(() => {
    loadData();
    // eslint-disable-next-line
  }, []);

  return <AppRouter />;
};

export default App;
