import { Card, Checkbox } from "antd";
import { CheckboxChangeEvent } from "antd/lib/checkbox";
import React, { FC } from "react";
import { useDispatch } from "react-redux";
import styled from "styled-components";
import { TERMS_OF_USE_URL } from "../../constants";
import checkout from "../../ducks/checkout";

export const Consent: FC = () => {
  const dispatch = useDispatch();

  const onChange = (e: CheckboxChangeEvent) => {
    const checked = e.target.checked;
    dispatch(checkout.actions.setHasUserConsent(checked));
  };

  return (
    <Card title="Terms of use">
      <Checkbox onChange={onChange}>
        I agree to the{" "}
        <Link href={TERMS_OF_USE_URL} target="_blank" rel="noopener noreferrer">
          terms of use
        </Link>
      </Checkbox>
    </Card>
  );
};

const Link = styled.a`
  &,
  &:hover {
    color: black;
    text-decoration: underline;
  }
`;
