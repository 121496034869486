import React, { FC, useEffect } from "react";
import styled from "styled-components";
import Header from "../components/header";
import { NoLocation } from "../components/location/selected-card";
import { useHistory } from "react-router-dom";
import { MENU_PAGE_PATH } from "../router";
import LineItems from "../components/item/line-items";
import Total from "../components/cost/total";
import { PaymentForm } from "../components/checkout/payment-form";
import useCalculateFinalCost from "../hooks/useCalculateFinalCost";
import useCheckoutStatus from "../hooks/useCheckoutStatus";
import { notification } from "antd";
import AccountAccess from "../components/account-access";
import BillingContact from "../components/billing-contact";
import useIdempotencyKey from "../hooks/useIdempotencyKey";
import Tip from "../components/cost/tip";
import { CheckoutTimeScheduler } from "../components/checkout/time-scheduler";
import { ConfirmCurrentLocationWithModal } from "../components/location/confirm-current-location";
import { useConfirmationTimeout } from "../hooks/useConfirmationTimeout";
import { Consent } from "../components/checkout/consent";
import useCart from "../hooks/useCart";

const Checkout: FC = () => {
  const history = useHistory();
  const finalCostReturnValue = useCalculateFinalCost();
  const {
    hasSelectedLocation,
    hasLineItems,
    hasSelectedAccessMethod,
    hasValidBillingContactInfo,
    hasPickedTime,
    lineItems,
    confirmedLocation,
    hasUserConsent,
    currentLocation,
  } = useCheckoutStatus();
  const { updateIdempotencyKey } = useIdempotencyKey();

  const { updateCartAvailability } = useCart();

  useConfirmationTimeout({ confirmedLocation });

  useEffect(() => {
    // Update the idempotency key on mount
    // Idempotency key is used to prevent duplicate orders/payments
    updateIdempotencyKey();
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    if (!hasLineItems) {
      history.push(MENU_PAGE_PATH);
      notification.open({
        message: "Add some food to your cart before checking out",
        placement: "bottomRight",
      });
    }
    if (currentLocation?.id) {
      updateCartAvailability(currentLocation.id);
    }
  });

  useEffect(() => {
    const { data } = finalCostReturnValue;
    if (
      data &&
      data.calculateOrder &&
      Array.isArray(data.calculateOrder.errors)
    ) {
      const error = data.calculateOrder.errors[0];
      if (error?.code === "NOT_FOUND") {
        notification.open({
          message:
            "One or more items in your cart may be unavailable. Please refresh the page to update your cart.",
        });
      }
    }
  });

  return (
    <Container>
      <Header floatContent={false}>
        <Title>Checkout</Title>
      </Header>
      <Content>
        <AccountAccess />
        {hasSelectedAccessMethod && [
          hasSelectedLocation ? (
            <ConfirmCurrentLocationWithModal />
          ) : (
            <NoLocation />
          ),
          hasSelectedLocation && !!confirmedLocation && hasLineItems
            ? [
                <LineItems
                  key="line_items"
                  lineItems={lineItems}
                  bordered
                  showTitle
                />,
                <Total
                  key="total"
                  finalCostReturnValue={finalCostReturnValue}
                />,
                <Tip key="tip" />,
                <CheckoutTimeScheduler key="checkout_time_scheduler" />,
                hasPickedTime
                  ? [
                      <BillingContact key="billing_contact" />,
                      hasValidBillingContactInfo
                        ? [
                            <Consent key="consent" />,
                            hasUserConsent ? (
                              <PaymentForm
                                key="payment_form"
                                finalCostReturnValue={finalCostReturnValue}
                              />
                            ) : null,
                          ]
                        : null,
                    ]
                  : null,
              ]
            : null,
        ]}
      </Content>
    </Container>
  );
};

const Container = styled.div``;

const Content = styled.main`
  padding: 1em;
  width: 600px;
  max-width: 100%;
  margin: auto;

  & > div {
    margin-bottom: 2em;
  }
`;

const Title = styled.h1`
  text-align: center;
`;

export default Checkout;
