import React from "react";
import ReactDOM from "react-dom";
import * as Sentry from "@sentry/react";
import "./index.css";
import App from "./App";
import * as serviceWorker from "./serviceWorker";
import { ApolloProvider } from "@apollo/client";
import { Provider } from "react-redux";
import client from "./graphql-client";
import store from "./store";
import { hotjar } from "./analytics";
import { IS_PRODUCTION } from "./constants";

const nameAndVersion = `${process.env.REACT_APP_NAME}@${process.env.REACT_APP_VERSION}`;

console.log(nameAndVersion);

if (IS_PRODUCTION) {
  // Track unhandled errors by Sentry in production
  Sentry.init({
    dsn: process.env.REACT_APP_SENTRY_DSN,
    release: nameAndVersion,
  });
  // Track with hotjar
  hotjar(process.env.REACT_APP_HOTJAR_HJID, process.env.REACT_APP_HOTJAR_HJSV);
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ApolloProvider client={client}>
        <App />
      </ApolloProvider>
    </Provider>
  </React.StrictMode>,
  document.getElementById("root")
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
