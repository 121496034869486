import moment from "moment";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { LOCATION_CONFIRMATION_TIMEOUT_MILLISECONDS } from "../constants";
import checkout from "../ducks/checkout";

/**
 * Show location confirmation modal if the confirmation has timed out
 */
export const useConfirmationTimeout = ({
  confirmedLocation,
}: {
  confirmedLocation: number | undefined;
}) => {
  const dispatch = useDispatch();

  const checkConfirmedLocation = () => {
    if (confirmedLocation === undefined) {
      return;
    }

    
    if (
      moment.now() - confirmedLocation >
      LOCATION_CONFIRMATION_TIMEOUT_MILLISECONDS
    ) {
      dispatch(checkout.actions.setConfirmedLocation(undefined));
      dispatch(checkout.actions.setShowReconfirmationLocationModal(true));
    }
  };

  useEffect(() => {
    checkConfirmedLocation();
    const intervalId = setInterval(checkConfirmedLocation, 1000);

    return () => clearInterval(intervalId);
    // eslint-disable-next-line
  }, [confirmedLocation]);
};
