import * as Types from './operations';

import gql from 'graphql-tag';
import * as ApolloReactCommon from '@apollo/react-common';
import * as ApolloReactHooks from '@apollo/react-hooks';
export const OrderFieldsFragmentDoc = gql`
    fragment orderFields on Order {
  id
  _id
  square_id
  location_id
  receipt_number
  receipt_url
  state
  updated_at
  total_money {
    amount
    currency
  }
  total_tax_money {
    amount
    currency
  }
  total_tip_money {
    amount
    currency
  }
  line_items {
    catalog_object_id
    quantity
    modifiers {
      catalog_object_id
    }
    note
  }
  fulfillments {
    pickup_details {
      note
      schedule_type
      prep_time_duration
      placed_at
      accepted_at
      rejected_at
      prep_time_duration
      pickup_at
    }
    state
    type
  }
}
    `;
export const LocationFieldsFragmentDoc = gql`
    fragment locationFields on Location {
  id
  square_id
  business_name
  phone_number
  business_email
  status
  description
  business_email
  coordinates {
    latitude
    longitude
  }
  address {
    address_line_1
    address_line_2
    locality
    administrative_district_level_1
    country
    postal_code
  }
  timezone
  business_hours {
    periods {
      day_of_week
      start_local_time
      end_local_time
    }
  }
  time_slot_increment
  lead_time
}
    `;
export const CalculateOrderDocument = gql`
    mutation CalculateOrder($input: OrderInput!) {
  calculateOrder(input: $input) {
    order {
      total_money {
        amount
        currency
      }
      total_tax_money {
        amount
        currency
      }
    }
    errors {
      detail
      category
      code
      field
    }
  }
}
    `;
export type CalculateOrderMutationFn = ApolloReactCommon.MutationFunction<Types.CalculateOrderMutation, Types.CalculateOrderMutationVariables>;

/**
 * __useCalculateOrderMutation__
 *
 * To run a mutation, you first call `useCalculateOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCalculateOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [calculateOrderMutation, { data, loading, error }] = useCalculateOrderMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCalculateOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CalculateOrderMutation, Types.CalculateOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<Types.CalculateOrderMutation, Types.CalculateOrderMutationVariables>(CalculateOrderDocument, baseOptions);
      }
export type CalculateOrderMutationHookResult = ReturnType<typeof useCalculateOrderMutation>;
export type CalculateOrderMutationResult = ApolloReactCommon.MutationResult<Types.CalculateOrderMutation>;
export type CalculateOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CalculateOrderMutation, Types.CalculateOrderMutationVariables>;
export const CreateOrderAndPayDocument = gql`
    mutation CreateOrderAndPay($orderRequest: OrderInput!, $paymentRequest: CreatePaymentRequestInput!, $recipient: OrderFulfillmentRecipientInput!, $requestedPickupTime: String, $orderNote: String) {
  createOrderAndPay(orderRequest: $orderRequest, paymentRequest: $paymentRequest, recipient: $recipient, requestedPickupTime: $requestedPickupTime, orderNote: $orderNote) {
    errors {
      field
      detail
      category
      code
    }
    payment {
      total_money {
        amount
        currency
      }
      receipt_url
      receipt_number
      status
    }
    order {
      ...orderFields
    }
  }
}
    ${OrderFieldsFragmentDoc}`;
export type CreateOrderAndPayMutationFn = ApolloReactCommon.MutationFunction<Types.CreateOrderAndPayMutation, Types.CreateOrderAndPayMutationVariables>;

/**
 * __useCreateOrderAndPayMutation__
 *
 * To run a mutation, you first call `useCreateOrderAndPayMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateOrderAndPayMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createOrderAndPayMutation, { data, loading, error }] = useCreateOrderAndPayMutation({
 *   variables: {
 *      orderRequest: // value for 'orderRequest'
 *      paymentRequest: // value for 'paymentRequest'
 *      recipient: // value for 'recipient'
 *      requestedPickupTime: // value for 'requestedPickupTime'
 *      orderNote: // value for 'orderNote'
 *   },
 * });
 */
export function useCreateOrderAndPayMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CreateOrderAndPayMutation, Types.CreateOrderAndPayMutationVariables>) {
        return ApolloReactHooks.useMutation<Types.CreateOrderAndPayMutation, Types.CreateOrderAndPayMutationVariables>(CreateOrderAndPayDocument, baseOptions);
      }
export type CreateOrderAndPayMutationHookResult = ReturnType<typeof useCreateOrderAndPayMutation>;
export type CreateOrderAndPayMutationResult = ApolloReactCommon.MutationResult<Types.CreateOrderAndPayMutation>;
export type CreateOrderAndPayMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CreateOrderAndPayMutation, Types.CreateOrderAndPayMutationVariables>;
export const RegisterDocument = gql`
    mutation register($input: UsersPermissionsRegisterEnhancedInput!) {
  registerEnhanced(input: $input) {
    user {
      confirmed
      username
    }
    jwt
  }
}
    `;
export type RegisterMutationFn = ApolloReactCommon.MutationFunction<Types.RegisterMutation, Types.RegisterMutationVariables>;

/**
 * __useRegisterMutation__
 *
 * To run a mutation, you first call `useRegisterMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRegisterMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [registerMutation, { data, loading, error }] = useRegisterMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useRegisterMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.RegisterMutation, Types.RegisterMutationVariables>) {
        return ApolloReactHooks.useMutation<Types.RegisterMutation, Types.RegisterMutationVariables>(RegisterDocument, baseOptions);
      }
export type RegisterMutationHookResult = ReturnType<typeof useRegisterMutation>;
export type RegisterMutationResult = ApolloReactCommon.MutationResult<Types.RegisterMutation>;
export type RegisterMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.RegisterMutation, Types.RegisterMutationVariables>;
export const LoginDocument = gql`
    mutation login($input: UsersPermissionsLoginInput!) {
  loginEnhanced(input: $input) {
    user {
      confirmed
      email
      firstName
      lastName
      phoneNumber
    }
    jwt
  }
}
    `;
export type LoginMutationFn = ApolloReactCommon.MutationFunction<Types.LoginMutation, Types.LoginMutationVariables>;

/**
 * __useLoginMutation__
 *
 * To run a mutation, you first call `useLoginMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLoginMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [loginMutation, { data, loading, error }] = useLoginMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useLoginMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.LoginMutation, Types.LoginMutationVariables>) {
        return ApolloReactHooks.useMutation<Types.LoginMutation, Types.LoginMutationVariables>(LoginDocument, baseOptions);
      }
export type LoginMutationHookResult = ReturnType<typeof useLoginMutation>;
export type LoginMutationResult = ApolloReactCommon.MutationResult<Types.LoginMutation>;
export type LoginMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.LoginMutation, Types.LoginMutationVariables>;
export const LostPasswordDocument = gql`
    mutation lostPassword($email: String!) {
  forgotPassword(email: $email) {
    ok
  }
}
    `;
export type LostPasswordMutationFn = ApolloReactCommon.MutationFunction<Types.LostPasswordMutation, Types.LostPasswordMutationVariables>;

/**
 * __useLostPasswordMutation__
 *
 * To run a mutation, you first call `useLostPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useLostPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [lostPasswordMutation, { data, loading, error }] = useLostPasswordMutation({
 *   variables: {
 *      email: // value for 'email'
 *   },
 * });
 */
export function useLostPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.LostPasswordMutation, Types.LostPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<Types.LostPasswordMutation, Types.LostPasswordMutationVariables>(LostPasswordDocument, baseOptions);
      }
export type LostPasswordMutationHookResult = ReturnType<typeof useLostPasswordMutation>;
export type LostPasswordMutationResult = ApolloReactCommon.MutationResult<Types.LostPasswordMutation>;
export type LostPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.LostPasswordMutation, Types.LostPasswordMutationVariables>;
export const ResetPasswordDocument = gql`
    mutation resetPassword($password: String!, $passwordConfirmation: String!, $code: String!) {
  resetPassword(password: $password, passwordConfirmation: $passwordConfirmation, code: $code) {
    jwt
  }
}
    `;
export type ResetPasswordMutationFn = ApolloReactCommon.MutationFunction<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;

/**
 * __useResetPasswordMutation__
 *
 * To run a mutation, you first call `useResetPasswordMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useResetPasswordMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [resetPasswordMutation, { data, loading, error }] = useResetPasswordMutation({
 *   variables: {
 *      password: // value for 'password'
 *      passwordConfirmation: // value for 'passwordConfirmation'
 *      code: // value for 'code'
 *   },
 * });
 */
export function useResetPasswordMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>) {
        return ApolloReactHooks.useMutation<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>(ResetPasswordDocument, baseOptions);
      }
export type ResetPasswordMutationHookResult = ReturnType<typeof useResetPasswordMutation>;
export type ResetPasswordMutationResult = ApolloReactCommon.MutationResult<Types.ResetPasswordMutation>;
export type ResetPasswordMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.ResetPasswordMutation, Types.ResetPasswordMutationVariables>;
export const UpdateMyContactInfoDocument = gql`
    mutation updateMyContactInfo($input: UserContactUpdateInput!) {
  updateMyContactInfo(input: $input) {
    firstName
    lastName
    phoneNumber
  }
}
    `;
export type UpdateMyContactInfoMutationFn = ApolloReactCommon.MutationFunction<Types.UpdateMyContactInfoMutation, Types.UpdateMyContactInfoMutationVariables>;

/**
 * __useUpdateMyContactInfoMutation__
 *
 * To run a mutation, you first call `useUpdateMyContactInfoMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateMyContactInfoMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateMyContactInfoMutation, { data, loading, error }] = useUpdateMyContactInfoMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateMyContactInfoMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdateMyContactInfoMutation, Types.UpdateMyContactInfoMutationVariables>) {
        return ApolloReactHooks.useMutation<Types.UpdateMyContactInfoMutation, Types.UpdateMyContactInfoMutationVariables>(UpdateMyContactInfoDocument, baseOptions);
      }
export type UpdateMyContactInfoMutationHookResult = ReturnType<typeof useUpdateMyContactInfoMutation>;
export type UpdateMyContactInfoMutationResult = ApolloReactCommon.MutationResult<Types.UpdateMyContactInfoMutation>;
export type UpdateMyContactInfoMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdateMyContactInfoMutation, Types.UpdateMyContactInfoMutationVariables>;
export const UpdatePickupTimeDocument = gql`
    mutation updatePickupTime($jooOrderId: String!, $requestedPickupTime: String!) {
  updatePickupTime(jooOrderId: $jooOrderId, requestedPickupTime: $requestedPickupTime) {
    order {
      ...orderFields
    }
    errors {
      field
      detail
      category
      code
    }
  }
}
    ${OrderFieldsFragmentDoc}`;
export type UpdatePickupTimeMutationFn = ApolloReactCommon.MutationFunction<Types.UpdatePickupTimeMutation, Types.UpdatePickupTimeMutationVariables>;

/**
 * __useUpdatePickupTimeMutation__
 *
 * To run a mutation, you first call `useUpdatePickupTimeMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePickupTimeMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePickupTimeMutation, { data, loading, error }] = useUpdatePickupTimeMutation({
 *   variables: {
 *      jooOrderId: // value for 'jooOrderId'
 *      requestedPickupTime: // value for 'requestedPickupTime'
 *   },
 * });
 */
export function useUpdatePickupTimeMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.UpdatePickupTimeMutation, Types.UpdatePickupTimeMutationVariables>) {
        return ApolloReactHooks.useMutation<Types.UpdatePickupTimeMutation, Types.UpdatePickupTimeMutationVariables>(UpdatePickupTimeDocument, baseOptions);
      }
export type UpdatePickupTimeMutationHookResult = ReturnType<typeof useUpdatePickupTimeMutation>;
export type UpdatePickupTimeMutationResult = ApolloReactCommon.MutationResult<Types.UpdatePickupTimeMutation>;
export type UpdatePickupTimeMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.UpdatePickupTimeMutation, Types.UpdatePickupTimeMutationVariables>;
export const CancelOrderDocument = gql`
    mutation cancelOrder($squareOrderId: String!) {
  cancelOrder(squareOrderId: $squareOrderId) {
    order {
      ...orderFields
    }
    errors {
      field
      code
      detail
      category
    }
  }
}
    ${OrderFieldsFragmentDoc}`;
export type CancelOrderMutationFn = ApolloReactCommon.MutationFunction<Types.CancelOrderMutation, Types.CancelOrderMutationVariables>;

/**
 * __useCancelOrderMutation__
 *
 * To run a mutation, you first call `useCancelOrderMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCancelOrderMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [cancelOrderMutation, { data, loading, error }] = useCancelOrderMutation({
 *   variables: {
 *      squareOrderId: // value for 'squareOrderId'
 *   },
 * });
 */
export function useCancelOrderMutation(baseOptions?: ApolloReactHooks.MutationHookOptions<Types.CancelOrderMutation, Types.CancelOrderMutationVariables>) {
        return ApolloReactHooks.useMutation<Types.CancelOrderMutation, Types.CancelOrderMutationVariables>(CancelOrderDocument, baseOptions);
      }
export type CancelOrderMutationHookResult = ReturnType<typeof useCancelOrderMutation>;
export type CancelOrderMutationResult = ApolloReactCommon.MutationResult<Types.CancelOrderMutation>;
export type CancelOrderMutationOptions = ApolloReactCommon.BaseMutationOptions<Types.CancelOrderMutation, Types.CancelOrderMutationVariables>;
export const Fetch_CatalogDocument = gql`
    query FETCH_CATALOG {
  catalogCategories(where: {available_on_joo: true}) {
    id
    name
    ordinal
    square_id
    items(where: {available_on_joo: true}) {
      id
    }
  }
  catalogItems(where: {available_on_joo: true}) {
    id
    name
    square_id
    description
    meta {
      image {
        url
      }
      present_at_all_locations
      present_at_location_ids
      absent_at_location_ids
    }
    modifier_list_info {
      id
      modifier_list_id
      enabled
      max_selected_modifiers
      min_selected_modifiers
      modifier_overrides {
        id
        on_by_default
        modifier_id
      }
      modifier_list {
        id
        square_id
      }
    }
    taxes {
      id
    }
  }
  catalogItemVariations {
    id
    square_id
    item_id
    name
    price_money {
      amount
      currency
    }
    pricing_type
    ordinal
  }
  catalogModifierLists {
    id
    square_id
    ordinal
    selection_type
    name
    modifiers {
      id
      square_id
    }
  }
  catalogModifiers {
    id
    square_id
    name
    ordinal
    modifier_list_id
    price_money {
      amount
      currency
    }
  }
  catalogTaxes {
    id
    square_id
    enabled
    percentage
    name
    calculation_phase
    inclusion_type
    applies_to_custom_amounts
  }
}
    `;

/**
 * __useFetch_CatalogQuery__
 *
 * To run a query within a React component, call `useFetch_CatalogQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetch_CatalogQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetch_CatalogQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetch_CatalogQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.Fetch_CatalogQuery, Types.Fetch_CatalogQueryVariables>) {
        return ApolloReactHooks.useQuery<Types.Fetch_CatalogQuery, Types.Fetch_CatalogQueryVariables>(Fetch_CatalogDocument, baseOptions);
      }
export function useFetch_CatalogLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.Fetch_CatalogQuery, Types.Fetch_CatalogQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Types.Fetch_CatalogQuery, Types.Fetch_CatalogQueryVariables>(Fetch_CatalogDocument, baseOptions);
        }
export type Fetch_CatalogQueryHookResult = ReturnType<typeof useFetch_CatalogQuery>;
export type Fetch_CatalogLazyQueryHookResult = ReturnType<typeof useFetch_CatalogLazyQuery>;
export type Fetch_CatalogQueryResult = ApolloReactCommon.QueryResult<Types.Fetch_CatalogQuery, Types.Fetch_CatalogQueryVariables>;
export const Fetch_LocationsDocument = gql`
    query FETCH_LOCATIONS {
  locations {
    ...locationFields
  }
}
    ${LocationFieldsFragmentDoc}`;

/**
 * __useFetch_LocationsQuery__
 *
 * To run a query within a React component, call `useFetch_LocationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useFetch_LocationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useFetch_LocationsQuery({
 *   variables: {
 *   },
 * });
 */
export function useFetch_LocationsQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.Fetch_LocationsQuery, Types.Fetch_LocationsQueryVariables>) {
        return ApolloReactHooks.useQuery<Types.Fetch_LocationsQuery, Types.Fetch_LocationsQueryVariables>(Fetch_LocationsDocument, baseOptions);
      }
export function useFetch_LocationsLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.Fetch_LocationsQuery, Types.Fetch_LocationsQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Types.Fetch_LocationsQuery, Types.Fetch_LocationsQueryVariables>(Fetch_LocationsDocument, baseOptions);
        }
export type Fetch_LocationsQueryHookResult = ReturnType<typeof useFetch_LocationsQuery>;
export type Fetch_LocationsLazyQueryHookResult = ReturnType<typeof useFetch_LocationsLazyQuery>;
export type Fetch_LocationsQueryResult = ApolloReactCommon.QueryResult<Types.Fetch_LocationsQuery, Types.Fetch_LocationsQueryVariables>;
export const LookupLocationWithSquareIdDocument = gql`
    query lookupLocationWithSquareId($square_id: String!) {
  locations(where: {square_id: $square_id}) {
    ...locationFields
  }
}
    ${LocationFieldsFragmentDoc}`;

/**
 * __useLookupLocationWithSquareIdQuery__
 *
 * To run a query within a React component, call `useLookupLocationWithSquareIdQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupLocationWithSquareIdQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupLocationWithSquareIdQuery({
 *   variables: {
 *      square_id: // value for 'square_id'
 *   },
 * });
 */
export function useLookupLocationWithSquareIdQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LookupLocationWithSquareIdQuery, Types.LookupLocationWithSquareIdQueryVariables>) {
        return ApolloReactHooks.useQuery<Types.LookupLocationWithSquareIdQuery, Types.LookupLocationWithSquareIdQueryVariables>(LookupLocationWithSquareIdDocument, baseOptions);
      }
export function useLookupLocationWithSquareIdLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LookupLocationWithSquareIdQuery, Types.LookupLocationWithSquareIdQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Types.LookupLocationWithSquareIdQuery, Types.LookupLocationWithSquareIdQueryVariables>(LookupLocationWithSquareIdDocument, baseOptions);
        }
export type LookupLocationWithSquareIdQueryHookResult = ReturnType<typeof useLookupLocationWithSquareIdQuery>;
export type LookupLocationWithSquareIdLazyQueryHookResult = ReturnType<typeof useLookupLocationWithSquareIdLazyQuery>;
export type LookupLocationWithSquareIdQueryResult = ApolloReactCommon.QueryResult<Types.LookupLocationWithSquareIdQuery, Types.LookupLocationWithSquareIdQueryVariables>;
export const LookupOrderDocument = gql`
    query lookupOrder($id: ID!) {
  order(id: $id) {
    ...orderFields
  }
}
    ${OrderFieldsFragmentDoc}`;

/**
 * __useLookupOrderQuery__
 *
 * To run a query within a React component, call `useLookupOrderQuery` and pass it any options that fit your needs.
 * When your component renders, `useLookupOrderQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLookupOrderQuery({
 *   variables: {
 *      id: // value for 'id'
 *   },
 * });
 */
export function useLookupOrderQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LookupOrderQuery, Types.LookupOrderQueryVariables>) {
        return ApolloReactHooks.useQuery<Types.LookupOrderQuery, Types.LookupOrderQueryVariables>(LookupOrderDocument, baseOptions);
      }
export function useLookupOrderLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LookupOrderQuery, Types.LookupOrderQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Types.LookupOrderQuery, Types.LookupOrderQueryVariables>(LookupOrderDocument, baseOptions);
        }
export type LookupOrderQueryHookResult = ReturnType<typeof useLookupOrderQuery>;
export type LookupOrderLazyQueryHookResult = ReturnType<typeof useLookupOrderLazyQuery>;
export type LookupOrderQueryResult = ApolloReactCommon.QueryResult<Types.LookupOrderQuery, Types.LookupOrderQueryVariables>;
export const LocationAvailabilityDocument = gql`
    query locationAvailability($locationId: String!) {
  locationAvailability(locationId: $locationId) {
    available
    timeSlot
  }
}
    `;

/**
 * __useLocationAvailabilityQuery__
 *
 * To run a query within a React component, call `useLocationAvailabilityQuery` and pass it any options that fit your needs.
 * When your component renders, `useLocationAvailabilityQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useLocationAvailabilityQuery({
 *   variables: {
 *      locationId: // value for 'locationId'
 *   },
 * });
 */
export function useLocationAvailabilityQuery(baseOptions?: ApolloReactHooks.QueryHookOptions<Types.LocationAvailabilityQuery, Types.LocationAvailabilityQueryVariables>) {
        return ApolloReactHooks.useQuery<Types.LocationAvailabilityQuery, Types.LocationAvailabilityQueryVariables>(LocationAvailabilityDocument, baseOptions);
      }
export function useLocationAvailabilityLazyQuery(baseOptions?: ApolloReactHooks.LazyQueryHookOptions<Types.LocationAvailabilityQuery, Types.LocationAvailabilityQueryVariables>) {
          return ApolloReactHooks.useLazyQuery<Types.LocationAvailabilityQuery, Types.LocationAvailabilityQueryVariables>(LocationAvailabilityDocument, baseOptions);
        }
export type LocationAvailabilityQueryHookResult = ReturnType<typeof useLocationAvailabilityQuery>;
export type LocationAvailabilityLazyQueryHookResult = ReturnType<typeof useLocationAvailabilityLazyQuery>;
export type LocationAvailabilityQueryResult = ApolloReactCommon.QueryResult<Types.LocationAvailabilityQuery, Types.LocationAvailabilityQueryVariables>;