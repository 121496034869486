import React, { FC } from "react";
import styled from "styled-components";

const Footer: FC = () => {
  return <FooterContainer>Jay Bird's ©2020</FooterContainer>;
};

const FooterContainer = styled.footer`
  text-align: center;
`;

export default Footer;
