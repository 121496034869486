import { useDispatch, useSelector } from "react-redux";
import { RootState } from "../ducks";
import useCurrentLocation from "./useCurrentLocation";
import { FinalCostReturnValue } from "./useCalculateFinalCost";
import useCreateOrderAndPay from "./useCreateOrderAndPay";
import {
  SqError,
  SqContact,
} from "react-square-payment-form/lib/components/models";
import checkout from "../ducks/checkout";
import useCheckoutStatus from "./useCheckoutStatus";
import useIdempotencyKey from "./useIdempotencyKey";

const usePaymentForm = (finalCost: FinalCostReturnValue) => {
  const dispatch = useDispatch();
  const { updateIdempotencyKey } = useIdempotencyKey();

  const { errorMessages, requestedPickupTime } = useSelector(
    (state: RootState) => state.checkout
  );
  const selectedLocation = useCurrentLocation();
  const locationSquareId = selectedLocation.current?.square_id ?? "";
  const contactInfo = useSelector((state: RootState) => state.user.contactInfo);
  const billingContactInfo = useSelector(
    (state: RootState) => state.checkout.billingContactInfo
  );
  const {
    hasLineItems,
    hasSelectedLocation,
    hasUserContactInfo,
  } = useCheckoutStatus();

  const {
    orderRequest,
    loading: finalCostLoading,
    data,
    error,
    total,
    tipAmount,
    prettyTotal,
  } = finalCost;

  const {
    createOrderAndPay,
    loading: paymentRequestLoading,
  } = useCreateOrderAndPay(orderRequest, tipAmount, requestedPickupTime);

  const viewable =
    !error &&
    !data?.calculateOrder.errors &&
    total !== null &&
    hasLineItems &&
    hasSelectedLocation &&
    hasUserContactInfo &&
    contactInfo?.firstName &&
    contactInfo?.email;

  const cardNonceResponseReceived = (
    errors: [SqError] | null,
    nonce: string,
  ) => {
    if (errors && errors.length > 0) {
      const nonNullErrors = errors.filter((error) => error);
      if (nonNullErrors.length > 0) {
        dispatch(
          checkout.actions.setErrorMessages(
            nonNullErrors.map((error) => error.message)
          )
        );
        return;
      }
    }
    // Create order and pay then update the idempotency key for next attempt
    createOrderAndPay(nonce).finally(() => {
      updateIdempotencyKey();
    });
  };

  const createVerificationDetails = () => {
    return {
      amount: String(total ?? 0),
      currencyCode: "USD",
      intent: "CHARGE",
      billingContact: billingContactInfo as SqContact,
    };
  };

  /**
   * Used for digital wallet requests.
   */
  const createPaymentRequest = () => {
    return {
      requestShippingAddress: false,
      requestBillingInfo: true,
      currencyCode: "USD",
      countryCode: "US",
      total: {
        label: "Jay Bird's",
        amount: String((total ?? 0) / 100),
        pending: false,
      },
    };
  };

  const inputStyles = [
    {
      _mozOsxFontSmoothing: "grayscale",
      _webkitFontSmoothing: "antialiased",
      backgroundColor: "white",
      color: "black",
      fontFamily: "Helvetica Neue",
      fontSize: "16px",
      lineHeight: "24px",
      padding: "16px",
      placeholderColor: "#CCC",
    },
  ];

  return {
    cardNonceResponseReceived,
    createVerificationDetails,
    createPaymentRequest,
    finalCostLoading,
    paymentRequestLoading,
    errorMessages,
    locationSquareId,
    viewable,
    prettyTotal,
    inputStyles,
  };
};

export default usePaymentForm;
