import React, { FC } from "react";
import { Card, Divider } from "antd";
import styled from "styled-components";
import { FinalCostReturnValue } from "../../hooks/useCalculateFinalCost";
import Error from "../error";

const Total: FC<{ finalCostReturnValue: FinalCostReturnValue }> = ({
  finalCostReturnValue,
}) => {
  const {
    loading,
    error,
    data,
    prettySubtotal,
    prettyTaxes,
    prettyTotal,
    prettyTipAmount,
  } = finalCostReturnValue;

  const content =
    !error && !data?.calculateOrder.errors ? (
      <TotalLines
        prettySubtotal={prettySubtotal}
        prettyTaxes={prettyTaxes}
        prettyTotal={prettyTotal}
        prettyTip={prettyTipAmount}
      />
    ) : (
      <Error error={error} />
    );

  return <Card loading={loading}>{content}</Card>;
};

export const TotalLines: FC<{
  prettySubtotal: string | null;
  prettyTaxes: string | null;
  prettyTotal: string | null;
  prettyTip?: string | null;
}> = ({ prettySubtotal, prettyTaxes, prettyTotal, prettyTip }) => {
  return (
    <div>
      <Line>
        <div>Subtotal</div>
        <div>{prettySubtotal ? prettySubtotal : "Error"}</div>
      </Line>
      <Divider />
      {prettyTip && (
        <>
          <Line>
            <div>Tip</div>
            <div>{prettyTip}</div>
          </Line>
          <Divider />
        </>
      )}
      <Line>
        <div>Tax</div>
        <div>{prettyTaxes ? prettyTaxes : "Error"}</div>
      </Line>
      <Divider />
      <Line>
        <div style={{ fontWeight: "bold" }}>Total</div>
        <div>{prettyTotal ? prettyTotal : "Error"}</div>
      </Line>
    </div>
  );
};

const Line = styled.div`
  display: flex;
  justify-content: space-between;
`;

export default Total;
