import React, { FC, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import checkout from "../../ducks/checkout";
import useCurrentLocation from "../../hooks/useCurrentLocation";
import { RootState } from "../../ducks";
import TimeScheduler from "../time-scheduler";
import { OnSetTimeFunc } from "../../types";
import {
  CART_PICKUP_TIME_REFRESH_INTERVAL,
  CHECKOUT_PICKUP_TIME_REFRESH_MESSAGE,
} from "../../constants";
import { notification } from "antd";

export const CheckoutTimeScheduler: FC = () => {
  const dispatch = useDispatch();
  const { requestedPickupTime } = useSelector(
    (state: RootState) => state.checkout
  );
  const { current } = useCurrentLocation();

  const onSetTime: OnSetTimeFunc = (timeStamp) => {
    return new Promise((resolve) => {
      dispatch(checkout.actions.setRequestedPickupTime(timeStamp));
      resolve();
    });
  };

  // Refresh the pickup time every so often
  useEffect(() => {
    const id = setTimeout(() => {
      if (requestedPickupTime) {
        onSetTime(undefined);
        notification.open({
          message: CHECKOUT_PICKUP_TIME_REFRESH_MESSAGE,
          placement: "bottomRight",
        });
      }
    }, CART_PICKUP_TIME_REFRESH_INTERVAL);
    return () => clearTimeout(id);
  });

  if (!current) {
    return <div>Please select a location first</div>;
  }

  return (
    <TimeScheduler
      onSetTime={onSetTime}
      shopLocation={current}
      requestedPickupTime={requestedPickupTime}
      showCancelButton
    />
  );
};

export default TimeScheduler;
