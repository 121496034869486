import React, { FC, useEffect } from "react";
import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect,
} from "react-router-dom";
import MenuPage from "./pages/menu";
import Locations from "./pages/locations";
import Checkout from "./pages/checkout";
import { removeLocalStorage } from "./util/persist-state";
import OrderPage from "./pages/order";
import Account from "./pages/account";

export const LOCATIONS_PAGE_PATH = "/locations";
export const MENU_PAGE_PATH = "/menu";
export const CHECKOUT_PAGE_PATH = "/checkout";
export const ORDER_PAGE_PATH = "/order";
export const REFRESH_PAGE_PATH = "/refresh";
export const ACCOUNT_PAGE_PATH = "/account";
export const PASSWORD_RESET_PAGE_PATH = "/account/password-reset/";

const AppRouter: FC = () => (
  <Router>
    <Switch>
      <Redirect exact from="/" to={LOCATIONS_PAGE_PATH} />
      <Route path={MENU_PAGE_PATH}>
        <MenuPage />
      </Route>
      <Route path={LOCATIONS_PAGE_PATH}>
        <Locations />
      </Route>
      <Route path={CHECKOUT_PAGE_PATH}>
        <Checkout />
      </Route>
      <Route path={`${ORDER_PAGE_PATH}/:orderId`}>
        <OrderPage />
      </Route>
      <Route path={ACCOUNT_PAGE_PATH}>
        <Account />
      </Route>
      <Route path={REFRESH_PAGE_PATH}>
        <Refresh />
      </Route>

      <Redirect to="/" />
    </Switch>
  </Router>
);

const Refresh: FC = () => {
  useEffect(() => {
    removeLocalStorage();
    window.location.href = LOCATIONS_PAGE_PATH;
  });

  return null;
};

export default AppRouter;
