import React, { FC } from "react";
import styled from "styled-components";

const Phone: FC<{ phoneNumber: string }> = ({ phoneNumber }) => {
  return (
    <div>
      <StyledAnchor href={`tel:${phoneNumber}`}>{phoneNumber}</StyledAnchor>
    </div>
  );
};

const StyledAnchor = styled.a`
  color: rgba(0, 0, 0, 0.85);
  text-decoration: underline;
  text-decoration-color: #ffeb19;
`;

export default Phone;
