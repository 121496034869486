import React, { FC } from "react";
import styled from "styled-components";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../ducks";
import { Drawer, Button } from "antd";
import ui from "../ducks/ui";

import SelectedCard from "./location/selected-card";
import { useHistory } from "react-router-dom";
import { CHECKOUT_PAGE_PATH } from "../router";
import LineItems from "./item/line-items";
import useCostCalculator from "../hooks/useCost";

const Cart: FC = () => {
  const dispatch = useDispatch();
  const lineItems = useSelector((state: RootState) => state.cart.lineItems);
  const cartVisible = useSelector((state: RootState) => state.ui.cartVisible);
  const selectedLocation = useSelector(
    (state: RootState) => state.location.selected
  );
  const { prettySubtotal } = useCostCalculator();
  const history = useHistory();

  const handleClose = () => dispatch(ui.actions.toggleCart());

  const canCheckout = lineItems.length > 0 && !!selectedLocation;

  const Footer: FC = () => {
    return (
      <Button
        block={true}
        shape="round"
        type="primary"
        size="large"
        disabled={!canCheckout}
        onClick={() => {
          history.push(CHECKOUT_PAGE_PATH);
          handleClose();
        }}
      >
        {`Checkout | ${prettySubtotal}`}
      </Button>
    );
  };

  return (
    <StyledDrawer
      visible={cartVisible}
      width={450}
      onClose={handleClose}
      title={
        <div>
          <h2>My Order</h2>
          <SelectedCardContainer>
            <SelectedCard />
          </SelectedCardContainer>
        </div>
      }
      footer={<Footer />}
    >
      <LineItems lineItems={lineItems} handleClose={handleClose} />
    </StyledDrawer>
  );
};

const StyledDrawer = styled(Drawer)`
  .ant-drawer-content-wrapper {
    max-width: 100%;
  }
  .ant-drawer-title {
    h2 {
      text-align: center;
    }
  }
  .ant-drawer-header {
    padding-right: 0;
    padding-left: 0;
    border-bottom: none;
  }
  .ant-list-header {
    border-bottom: none;
  }
  .ant-drawer-body {
    padding: 0;
  }
`;

const SelectedCardContainer = styled.div`
  padding: 0 0.5em 0em;
`;

export default Cart;
