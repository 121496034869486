import { useDispatch, useSelector } from "react-redux";
import { v4 as uuidv4 } from "uuid";
import { RootState } from "../ducks";
import checkout from "../ducks/checkout";

const generateIdempotencyKey = () => {
  return uuidv4();
};

const useIdempotencyKey = () => {
  const dispatch = useDispatch();
  const { idempotencyKey } = useSelector((state: RootState) => state.checkout);

  const getIdempotencyKey = () => {
    if (idempotencyKey !== undefined && idempotencyKey.length > 0) {
      return idempotencyKey;
    } else {
      const newIdempotencyKey = generateIdempotencyKey();
      dispatch(checkout.actions.updateIdempotencyKey(newIdempotencyKey));
      return newIdempotencyKey;
    }
  };

  const updateIdempotencyKey = () => {
    dispatch(checkout.actions.updateIdempotencyKey(generateIdempotencyKey()));
  };

  return {
    getIdempotencyKey,
    updateIdempotencyKey,
  };
};

export default useIdempotencyKey;
