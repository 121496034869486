import { useSelector } from "react-redux";
import { RootState } from "../ducks";
import {
  calculateSubtotal,
  prettyMoney,
  calculateTotalTaxes,
} from "../helpers";

interface Output {
  subtotal: number;
  taxes: number;
  total: number;
  prettySubtotal: string;
  prettyTaxes: string;
  prettyTotal: string;
}

const useCostCalculator = (): Output => {
  const lineItems = useSelector((state: RootState) => state.cart.lineItems);
  const { modifiers, variations, items, taxes: taxObjects } = useSelector(
    (state: RootState) => state.catalog.data
  );

  const subtotal = calculateSubtotal(modifiers, variations, lineItems);
  const taxes = calculateTotalTaxes(
    lineItems,
    items,
    taxObjects,
    modifiers,
    variations
  );
  const total = subtotal + taxes;

  const prettySubtotal = prettyMoney(subtotal);
  const prettyTaxes = prettyMoney(taxes);
  const prettyTotal = prettyMoney(total);

  return {
    subtotal,
    taxes,
    total,
    prettySubtotal,
    prettyTaxes,
    prettyTotal,
  };
};

export default useCostCalculator;
