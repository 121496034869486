import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { ViewType } from "../types";

interface UIInitialSliceState {
  cartVisible: boolean;
  locationListVisible: boolean;
  accountAccessView: ViewType;
}

const initialState: UIInitialSliceState = {
  cartVisible: false,
  locationListVisible: true,
  accountAccessView: undefined,
};

const ui = createSlice({
  name: "ui",
  initialState,
  reducers: {
    toggleCart(state) {
      state.cartVisible = !state.cartVisible;
    },
    updateLocationListVisible(state, action: PayloadAction<boolean>) {
      state.locationListVisible = action.payload;
    },
    setAccountAccessView(state, action: PayloadAction<ViewType>) {
      state.accountAccessView = action.payload;
    },
  },
});

export default ui;
