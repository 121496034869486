import { readEnv } from "./helpers";

export const IS_PRODUCTION = process.env.NODE_ENV === "production";
export const IS_SQUARE_SANDBOX_MODE =
  process.env.REACT_APP_SQUARE_SANDBOX_MODE?.trim() === "true";
export const SQUARE_APPLICATION_ID = readEnv("REACT_APP_SQUARE_APPLICATION_ID");

// Allow for up to the amount of minutes in a day
export const MAX_TIME_SLOTS = 60 * 24;

export const SQUARE_TIME_INPUT_FORMAT = "HH:mm:ss";
export const DATE_INPUT_FORMAT = "YYYY-MM-DD";
export const DISPLAY_TIME_FORMAT = "h:mm a";
export const HUMAN_OUTPUT_FORMAT = `h:mm a`;
export const COMBINED_DATE_TIME_FORMAT = `${DATE_INPUT_FORMAT} ${DISPLAY_TIME_FORMAT}`;
export const COMBINED_DATE_TIME_FORMAT_WITH_TIME_ZONE = `${COMBINED_DATE_TIME_FORMAT} Z`;

/** 5 minutes */
export const CART_PICKUP_TIME_REFRESH_INTERVAL = 1000 * 60 * 5;

/** 30 minutes */
export const LOCATION_CONFIRMATION_TIMEOUT_MILLISECONDS = 1000 * 60 * 30;

/** 1 minute */
export const LOCATION_AVAILABILITY_POLL_INTERVAL = 1000 * 60;
export const CHECKOUT_PICKUP_TIME_REFRESH_MESSAGE =
  "Please select a new pickup time";
export const MISSED_LEAD_TIME_MESSAGE =
  "The store is about to close. Please call the store directly to see if there is enough time to make your order!";
export const REQUEST_TIME_NO_LONGER_AVAILABLE_MESSAGE =
  "Please select a new pickup time. Your requested pickup time is no longer available.";

export const TERMS_OF_USE_URL = "https://jaybirdschicken.com/terms-of-use/";
