import React, { FC } from "react";
import styled from "styled-components";
import LocationCard from "./card";
import { useHistory } from "react-router-dom";
import { Button, Card } from "antd";
import { LOCATIONS_PAGE_PATH } from "../../router";
import useCurrentLocation from "../../hooks/useCurrentLocation";

const SelectedCard: FC<{
  isPreview?: boolean;
  showMapPreview?: boolean;
  showActionButton?: boolean;
}> = ({ isPreview = false, showMapPreview, showActionButton }) => {
  const { current: chosenLocation } = useCurrentLocation();

  return chosenLocation ? (
    <LocationCard
      location={chosenLocation}
      mode={isPreview ? "preview" : "single"}
      showMapPreview={showMapPreview}
      showActionButton={showActionButton}
    />
  ) : (
    <NoLocation />
  );
};

export const NoLocation: FC = () => {
  const history = useHistory();
  return (
    <StyledCard
      title="No location selected"
      headStyle={{ textAlign: "center" }}
      bodyStyle={{ textAlign: "center" }}
    >
      <Button type="primary" onClick={() => history.push(LOCATIONS_PAGE_PATH)}>
        Pick a location!
      </Button>
    </StyledCard>
  );
};

const StyledCard = styled(Card)`
  margin: 0 0.5em 1em;
`;

export default SelectedCard;
