import React, { FC } from "react";
import styled from "styled-components";
import { Card, Form, Input, Button, Select, Divider } from "antd";
import { Store } from "antd/lib/form/interface";
import { useDispatch, useSelector } from "react-redux";
import { FormInstance } from "antd/lib/form";
import { RootState } from "../ducks";
import countries from "iso-3166-1/dist/iso-3166";
import { MailOutlined, UserOutlined } from "@ant-design/icons";
import { SqContact } from "react-square-payment-form/lib/components/models";
import checkout from "../ducks/checkout";

const { Option } = Select;

const BillingContact: FC = () => {
  const [form] = Form.useForm();
  const dispatch = useDispatch();
  const { billingContactInfo, editingBillingContactInfo } = useSelector(
    (state: RootState) => state.checkout
  );
  const isPreview = !!billingContactInfo && !editingBillingContactInfo;

  const handleButtonClick = () => {
    if (!isPreview) {
      form.submit();
    } else {
      dispatch(
        checkout.actions.updateEditingBillingContactInfo({ isEditing: true })
      );
    }
  };

  const handleSubmit = (values: Store) => {
    const {
      givenName,
      familyName,
      email,
      postalCode,
      country,
      region,
      city,
      street1,
      street2,
    } = values;
    const addressLines = street2 ? [street1, street2] : [street1];

    const billingContactInfo: SqContact = {
      givenName,
      familyName,
      email,
      country,
      region,
      city,
      postalCode,
      addressLines,
    };
    dispatch(checkout.actions.updateBillingContactInfo(billingContactInfo));
    dispatch(
      checkout.actions.updateEditingBillingContactInfo({ isEditing: false })
    );
  };

  return (
    <StyledCard
      title="Billing contact info"
      actions={[
        <Button
          type={isPreview ? "text" : "primary"}
          size="large"
          shape="round"
          block
          onClick={handleButtonClick}
          className="billing-contact-action-btn"
        >
          {isPreview ? "Edit" : "Continue"}
        </Button>,
      ]}
    >
      {isPreview ? (
        <Preview contactInfo={billingContactInfo} />
      ) : (
        <ContactInfoForm form={form} handleSubmit={handleSubmit} />
      )}
    </StyledCard>
  );
};

const Preview: FC<{ contactInfo: SqContact | undefined }> = ({
  contactInfo,
}) => {
  if (contactInfo === undefined) {
    return <div>Missing contact info</div>;
  }

  const { givenName, familyName, email } = contactInfo;
  return (
    <div>
      <div>{`${givenName} ${familyName}`}</div>
      <div>{`${email}`}</div>
      <div>...</div>
    </div>
  );
};

const ContactInfoForm: FC<{
  form: FormInstance;
  handleSubmit: (values: Store) => void;
}> = ({ form, handleSubmit }) => {
  const billingContactInfo = useSelector(
    (state: RootState) => state.checkout.billingContactInfo
  );

  const initialValues = billingContactInfo
    ? {
        ...billingContactInfo,
        street1: billingContactInfo.addressLines[0],
        street2: billingContactInfo.addressLines[1],
      }
    : undefined;

  return (
    <Form
      form={form}
      initialValues={initialValues}
      name="contact_info"
      scrollToFirstError
      colon={false}
      hideRequiredMark
      onFinish={handleSubmit}
    >
      <StyledItem
        name="givenName"
        rules={[
          {
            required: true,
            message: "Please input first name",
          },
        ]}
      >
        <Input placeholder="First name" prefix={<UserOutlined />} />
      </StyledItem>
      <StyledItem
        name="familyName"
        rules={[
          {
            required: true,
            message: "Please input last name",
          },
        ]}
      >
        <Input placeholder="Last name" prefix={<UserOutlined />} />
      </StyledItem>
      <StyledItem
        name="email"
        rules={[
          {
            required: true,
            message: "Please input an email address",
          },
          {
            type: "email",
            message: "Please enter a valid email address",
          },
        ]}
      >
        <Input placeholder="Email" prefix={<MailOutlined />} />
      </StyledItem>

      <Divider>Billing address</Divider>

      <StyledItem
        name="street1"
        rules={[
          {
            required: true,
            message: "Please enter your billing street address",
          },
        ]}
      >
        <Input placeholder="Your billing street address" />
      </StyledItem>
      <StyledItem name="street2">
        <Input placeholder="Your billing street address (line 2)" />
      </StyledItem>
      <StyledItem
        name="city"
        rules={[{ required: true, message: "Please enter your billing city" }]}
      >
        <Input placeholder="Your billing city" />
      </StyledItem>
      <StyledItem
        name="region"
        rules={[{ required: true, message: "Please enter your billing state" }]}
      >
        <Input placeholder="Your billing state" />
      </StyledItem>
      <StyledItem
        name="postalCode"
        rules={[
          { required: true, message: "Please enter your billing zip code" },
        ]}
      >
        <Input placeholder="Your billing zip code" />
      </StyledItem>
      <StyledItem
        name="country"
        rules={[
          { required: true, message: "Please enter your billing country" },
        ]}
        initialValue="US"
      >
        <Select
          showSearch
          optionFilterProp="children"
          placeholder="Country"
          className="billing-contact-country-dropdown"
          filterOption={(input, option) =>
            option?.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
          }
        >
          {countries.map((country) => (
            <Option value={country.alpha2.toUpperCase()}>
              {country.country}
            </Option>
          ))}
        </Select>
      </StyledItem>
    </Form>
  );
};

const StyledCard = styled(Card)``;
const StyledItem = styled(Form.Item)`
  display: block;
`;

export default BillingContact;
