import React, { FC } from "react";
import Login from "./login";
import Register from "./register";
import LostPassword from "./lost-password";
import { useRouteMatch } from "react-router-dom";
import PasswordReset from "./password-reset";
import { Button } from "antd";
import styled from "styled-components";
import Contact from "./contact";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../ducks";
import { ViewType } from "../../types";
import ui from "../../ducks/ui";

export type ViewTypeUpdater = (
  type: ViewType
) => {
  payload: ViewType;
  type: string;
};

const AccountAccess: FC = () => {
  const match = useRouteMatch({
    path: "/account/password-reset/",
    strict: true,
  });

  const { isGuest, loggedIn } = useSelector((state: RootState) => state.user);
  const { accountAccessView: type } = useSelector(
    (state: RootState) => state.ui
  );
  const dispatch = useDispatch();

  const setType = (type: ViewType) =>
    dispatch(ui.actions.setAccountAccessView(type));

  const Choices: FC = () => {
    return (
      <Container>
        <Button
          size="large"
          type="primary"
          shape="round"
          block
          onClick={() => setType("login")}
        >
          Login
        </Button>
        <Button
          size="large"
          type="primary"
          shape="round"
          block
          onClick={() => setType("register")}
        >
          Create account
        </Button>
        {!loggedIn && (
          <Button
            size="large"
            type="ghost"
            shape="round"
            block
            onClick={() => setType("guest")}
          >
            Continue as guest
          </Button>
        )}
      </Container>
    );
  };

  if (!!match) {
    return <PasswordReset setType={setType} />;
  } else if (isGuest || loggedIn || type === "guest") {
    return <Contact setType={setType} />;
  } else if (type === "login") {
    return <Login setType={setType} />;
  } else if (type === "register") {
    return <Register setType={setType} />;
  } else if (type === "passwordReset") {
    return <LostPassword setType={setType} />;
  } else {
    return <Choices />;
  }
};

const Container = styled.div`
  display: flex;
  flex-direction: column;

  button {
    margin-bottom: 1em;
  }
`;

export default AccountAccess;
