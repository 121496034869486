import GoogleMapReact, { Coords } from "google-map-react";
import React, { FC } from "react";

export const MapComponent: FC<{
  center?: Coords | undefined;
  zoom?: number | undefined;
}> = ({ children, center, zoom }) => {
  return (
    <GoogleMapReact
      debounced={true}
      bootstrapURLKeys={{
        key: process.env?.REACT_APP_GOOGLE_MAPS_API_KEY ?? "",
      }}
      center={center}
      zoom={zoom}
    >
      {children}
    </GoogleMapReact>
  );
};
