import React, { FC } from "react";
import { Button, Card, Form, InputNumber } from "antd";
import styled from "styled-components";
import { Store } from "antd/lib/form/interface";
import { useDispatch, useSelector } from "react-redux";
import checkout from "../../ducks/checkout";
import { TipData } from "../../types";
import { RootState } from "../../ducks";
import { prettyMoney } from "../../helpers";
import { CheckOutlined } from "@ant-design/icons";

const tips = [15, 20, 25];

const Tip: FC = () => {
  const dispatch = useDispatch();
  const { tip } = useSelector((state: RootState) => state.checkout);
  const updateTip = (tip: TipData) => dispatch(checkout.actions.updateTip(tip));
  const editTip = (value: boolean) => dispatch(checkout.actions.editTip(value));

  if (tip && !tip.beingEdited) {
    let content = "";
    switch (tip.type) {
      case "cents":
        content = `${prettyMoney(tip?.value ?? 0)}`;
        break;
      case "percentage":
        content = `${tip.value}%`;
        break;
      case "none":
        content = `No tip`;
        break;
    }
    return (
      <Card
        title="Tip"
        actions={[
          <Button
            type="text"
            size="large"
            block
            shape="round"
            onClick={() => editTip(true)}
          >
            Change tip
          </Button>,
        ]}
      >
        {content}
      </Card>
    );
  } else {
    return (
      <Card
        title="Tip"
        actions={
          tip && [
            <Button
              type="primary"
              shape="round"
              size="large"
              block
              onClick={() => editTip(false)}
            >
              Cancel change
            </Button>,
          ]
        }
      >
        {tips.map((percentage, idx) => (
          <StyledButton
            key={`tip-${idx}`}
            shape="round"
            block
            size="large"
            type="primary"
            icon={
              tip && tip.type === "percentage" && tip.value === percentage ? (
                <CheckOutlined />
              ) : null
            }
            onClick={() => updateTip({ type: "percentage", value: percentage })}
          >
            {`${percentage}%`}
          </StyledButton>
        ))}
        <StyledButton
          shape="round"
          block
          size="large"
          type="dashed"
          icon={tip && tip.type === "none" ? <CheckOutlined /> : null}
          onClick={() => updateTip({ type: "none" })}
        >
          No tip
        </StyledButton>
        <CustomAmountForm updateTipFunc={updateTip} currentTip={tip} />
      </Card>
    );
  }
};

const CustomAmountForm: FC<{
  currentTip?: TipData;
  updateTipFunc: (tip: TipData) => void;
}> = ({ currentTip, updateTipFunc }) => {
  const [form] = Form.useForm();
  const handleSubmit = (values: Store) => {
    console.log({ values });
    updateTipFunc({ type: "cents", value: (values?.amount ?? 0) * 100 });
  };
  return (
    <Form
      form={form}
      name="tip_custom"
      scrollToFirstError
      colon={false}
      hideRequiredMark
      onFinish={handleSubmit}
      initialValues={{
        amount:
          currentTip && currentTip.type === "cents"
            ? currentTip?.value ?? 0
            : 0,
      }}
    >
      <InlineFormItem
        name="amount"
        rules={[
          {
            required: true,
            message: "Please enter a dollar amount",
          },
        ]}
      >
        <InputNumber
          style={{ width: "100%" }}
          precision={2}
          formatter={(value) => `$ ${value}`}
        />
      </InlineFormItem>
      <InlineFormItem>
        <StyledButton
          block
          htmlType="submit"
          type={
            currentTip && currentTip.type === "cents" ? "primary" : "dashed"
          }
        >
          Add custom amount
        </StyledButton>
      </InlineFormItem>
    </Form>
  );
};

const StyledButton = styled(Button)`
  margin-bottom: 1em;
`;
const InlineFormItem = styled(Form.Item)`
  display: inline-block;
  width: 50%;
`;

export default Tip;
