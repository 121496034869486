import React, { FC } from "react";
import { Button, Empty, List, Card, Input, Divider } from "antd";
import styled from "styled-components";
import { LineItemDetails, ItemModal } from "./item";
import logo from "../../logo.svg";
import { useSelector, useDispatch } from "react-redux";
import { RootState } from "../../ducks";
import { BuiltItem } from "../../types";
import _ from "lodash";
import cart from "../../ducks/cart";

const { TextArea } = Input;

const DELAY_TIME_MS = 100;

const ListItems: FC<{
  lineItems: BuiltItem[];
  showTitle?: boolean;
  bordered?: boolean;
  handleClose?: (event: React.MouseEvent<HTMLElement, MouseEvent>) => void;
  errorMessage?: string;
  canEdit?: boolean;
  orderNote?: string;
}> = ({
  lineItems,
  showTitle = false,
  bordered = false,
  handleClose,
  errorMessage,
  canEdit = true,
  orderNote,
}) => {
  const dispatch = useDispatch();
  const catalogItems = useSelector(
    (state: RootState) => state.catalog.data.items
  );
  const { currentEditedLineItemIndex, note: cartNote } = useSelector(
    (state: RootState) => state.cart
  );

  const note = canEdit ? cartNote : orderNote;

  const updateNoteDebounced = _.debounce(
    (value: string) => {
      dispatch(cart.actions.updateNote({ note: value }));
    },
    DELAY_TIME_MS,
    { maxWait: 1000 }
  );

  const handleNoteChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
    updateNoteDebounced(e.target.value);
  };

  const EmptyOrder: FC = () => {
    return (
      <Empty
        style={{ paddingTop: "1em" }}
        image={logo}
        description={
          <div>
            <p>
              Your order is empty. Go add something from the menu to get
              started!
            </p>
            <Button type="primary" onClick={handleClose}>
              Go to menu!
            </Button>
          </div>
        }
      />
    );
  };

  return (
    <Card
      bordered={bordered}
      title={showTitle ? "Items" : null}
      actions={errorMessage ? [<p>{errorMessage}</p>] : []}
    >
      {lineItems.length > 0 ? (
        <>
          <List>
            {lineItems.map((lineItem, index) => {
              const modalOpen = index === currentEditedLineItemIndex;
              const lineItemIndex = canEdit ? index : undefined;
              return (
                <ItemContainer key={index}>
                  <LineItemDetails
                    id={lineItem.item_id}
                    builtItem={lineItem}
                    lineItemIndex={lineItemIndex}
                  />
                  <ItemModal
                    data={catalogItems[lineItem.item_id]}
                    visible={modalOpen}
                  />
                </ItemContainer>
              );
            })}
          </List>
          <div>
            {note || canEdit ? (
              <label>
                <Divider>Order notes</Divider>
              </label>
            ) : null}
            {canEdit ? (
              [
                <TextArea
                  key="order_notes_text_area"
                  rows={2}
                  maxLength={100}
                  onChange={handleNoteChange}
                  defaultValue={note}
                />,
                <small key="hint">Max length: 100 characters</small>,
              ]
            ) : note ? (
              <p>{note}</p>
            ) : null}
          </div>
        </>
      ) : (
        <EmptyOrder />
      )}
    </Card>
  );
};

const ItemContainer = styled.div``;

export default ListItems;
