import { gql } from "@apollo/client";

export const ORDER_FIELDS_FRAGMENT = gql`
  fragment orderFields on Order {
    id
    _id
    square_id
    location_id
    receipt_number
    receipt_url
    state
    updated_at
    total_money {
      amount
      currency
    }
    total_tax_money {
      amount
      currency
    }
    total_tip_money {
      amount
      currency
    }
    line_items {
      catalog_object_id
      quantity
      modifiers {
        catalog_object_id
      }
      note
    }
    fulfillments {
      pickup_details {
        note
        schedule_type
        prep_time_duration
        placed_at
        accepted_at
        rejected_at
        prep_time_duration
        pickup_at
      }
      state
      type
    }
  }
`;

export const LOCATION_FIELDS_FRAGMENT = gql`
  fragment locationFields on Location {
    id
    square_id
    business_name
    phone_number
    business_email
    status
    description
    business_email
    coordinates {
      latitude
      longitude
    }
    address {
      address_line_1
      address_line_2
      locality
      administrative_district_level_1
      country
      postal_code
    }
    timezone
    business_hours {
      periods {
        day_of_week
        start_local_time
        end_local_time
      }
    }
    time_slot_increment
    lead_time
  }
`;
