import { combineReducers } from "redux";
import catalog from "./catalog";
import cart from "./cart";
import ui from "./ui";
import location from "./location";
import user from "./user";
import checkout from "./checkout";

const rootReducer = combineReducers({
  catalog: catalog.reducer,
  cart: cart.reducer,
  ui: ui.reducer,
  location: location.reducer,
  user: user.reducer,
  checkout: checkout.reducer
});

export type RootState = ReturnType<typeof rootReducer>;

export default rootReducer;
