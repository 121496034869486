import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { BuiltItem } from "../types";

interface CartInitialSliceState {
  lineItems: BuiltItem[];
  currentEditedLineItemIndex?: number;
  note?: string;
}

const initialState: CartInitialSliceState = {
  lineItems: [],
};

const cart = createSlice({
  name: "cart",
  initialState,
  reducers: {
    add(state, action: PayloadAction<{ selection: BuiltItem }>) {
      state.lineItems.push(action.payload.selection);
    },
    remove(state, action: PayloadAction<{ lineItemIndex: number }>) {
      state.lineItems.splice(action.payload.lineItemIndex, 1);
    },
    removeMultipleByJooId(state, action: PayloadAction<{jooIds: string[]}>) {
      for (let jooId of action.payload.jooIds) {
        const indexToRemove = state.lineItems.findIndex(item => item.item_id === jooId)
        state.lineItems.splice(indexToRemove, 1);
      }
    },
    editLineItem(state, action: PayloadAction<{ lineItemIndex: number }>) {
      state.currentEditedLineItemIndex = action.payload.lineItemIndex;
    },
    cancelEditLineItem(state) {
      state.currentEditedLineItemIndex = undefined;
    },
    replaceEditedLineItem(
      state,
      action: PayloadAction<{ builtItem: BuiltItem }>
    ) {
      const index = state.currentEditedLineItemIndex;
      if (index !== undefined) {
        state.lineItems.splice(index, 1, action.payload.builtItem);
      }
    },
    resetCart(state) {
      state.lineItems = [];
      state.currentEditedLineItemIndex = undefined;
      state.note = undefined;
    },
    updateNote(state, action: PayloadAction<{note: string | undefined}>) {
      state.note = action.payload.note;
    }
  },
});

export default cart;
