import { useDispatch } from "react-redux";
import user from "../ducks/user";
import client from "../graphql-client";
import { removeLocalStorage } from "../util/persist-state";
import checkout from "../ducks/checkout";

const useLogout = () => {
  const dispatch = useDispatch();

  const logout = () => {
    dispatch(user.actions.logout());
    dispatch(checkout.actions.reset());
    client.resetStore();
    removeLocalStorage();
  };

  return { logout };
};

export default useLogout;
