import React, { FC } from "react";
import styled from "styled-components";
import { Layout } from "../components/styled";
import Header from "../components/header";
import Footer from "../components/footer";
import AccountAccess from "../components/account-access/";
import { useSelector } from "react-redux";
import { RootState } from "../ducks";
import useLogout from "../hooks/useLogout";
import { Button } from "antd";

const AccountPage: FC = () => {
  const { loggedIn, contactInfo } = useSelector(
    (state: RootState) => state.user
  );
  const { logout } = useLogout();

  return (
    <Layout>
      <Header></Header>
      <Content>
        {loggedIn ? (
          <Center>
            <h2 style={{ textAlign: "center" }}>{`Hello ${
              contactInfo?.firstName ?? "there"
            }!`}</h2>
            <Button shape="round" onClick={logout}>
              Logout
            </Button>
          </Center>
        ) : (
          <AccountAccess />
        )}
      </Content>
      <Footer />
    </Layout>
  );
};

const Content = styled.main`
  padding: 1em;
  width: 600px;
  max-width: 100%;
  margin: auto;

  & > div {
    margin-bottom: 2em;
  }
`;

const Center = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

export default AccountPage;
