import React, { FC } from "react";
import { MapAllLocations } from "../components/location/map-all-locations";
import styled from "styled-components";
import List from "../components/location/list";
import { Button } from "antd";
import { useDispatch } from "react-redux";
import ui from "../ducks/ui";
import Header from "../components/header";

const Locations: FC = () => {
  return (
    <Container>
      <Header />
      <Content>
        <List />
        <MapAllLocations />
      </Content>
      <Footer />
    </Container>
  );
};

const Footer: FC = () => {
  const dispatch = useDispatch();
  return (
    <FooterContainer>
      <StyledButton
        onClick={() => dispatch(ui.actions.updateLocationListVisible(false))}
      >
        Map
      </StyledButton>
      <StyledButton
        onClick={() => dispatch(ui.actions.updateLocationListVisible(true))}
      >
        List
      </StyledButton>
    </FooterContainer>
  );
};

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 100vh;
  width: 100%;
`;

const Content = styled.div`
  display: flex;
  align-items: center;

  width: 100%;
  height: calc(100% - 50px);

  @media (min-width: 900px) {
    height: 100%;
  }
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-evenly;
  align-items: center;

  height: 50px;
  padding: 0 1em;

  @media (min-width: 900px) {
    display: none;
  }
`;
const StyledButton = styled(Button)`
  width: 100%;
  margin: 0 1em;
`;

export default Locations;
