import { Button, Card, Divider, Modal } from "antd";
import moment from "moment";
import React, { FC } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { RootState } from "../../ducks";
import checkout from "../../ducks/checkout";
import { LOCATIONS_PAGE_PATH } from "../../router";
import SelectedLocation from "./selected-card";

const ConfirmCurrentLocation: FC = () => {
  const dispatch = useDispatch();
  const { confirmedLocation } = useSelector((root: RootState) => root.checkout);

  const handleConfirm = (value: number | undefined) => {
    dispatch(checkout.actions.setConfirmedLocation(value));
  };

  const ConfirmationOptions = [
    <Button
      onClick={() =>
        handleConfirm(
          confirmedLocation === undefined ? moment.now() : undefined
        )
      }
      type={confirmedLocation ? "primary" : "text"}
      block
      className="custom-action-button"
    >
      Yes, that's right
    </Button>,
    <Link
      onClick={() => handleConfirm(undefined)}
      to={LOCATIONS_PAGE_PATH}
      className="custom-action-button"
    >
      No, switch location
    </Link>,
  ];

  return (
    <StyledCard
      title="Confirm store location"
      actions={[...ConfirmationOptions]}
      className="confirm-current-location-card"
    >
      <SelectedContainer>
        <SelectedLocation
          key="select_card"
          showMapPreview
          showActionButton={false}
        />
      </SelectedContainer>
      <Divider />
      <ConfirmMessage>Does this location look right?</ConfirmMessage>
    </StyledCard>
  );
};

export const ConfirmCurrentLocationWithModal: FC = () => {
  const { showReconfirmationLocationModal } = useSelector(
    (root: RootState) => root.checkout
  );
  return (
    <>
      <ConfirmCurrentLocation />
      <StyledModal
        title="Please reconfirm the store location"
        visible={showReconfirmationLocationModal}
        footer={null}
        closable={false}
      >
        <ConfirmCurrentLocation />
      </StyledModal>
    </>
  );
};

const StyledCard = styled(Card)`
  .ant-card-body {
    padding-top: 1px;
  }
  .ant-card-actions > li {
    margin: 0;
    .custom-action-button {
      padding: 1em 0;
    }
    &,
    & > span a:not(.ant-btn),
    .ant-btn {
      color: black;
      font-weight: 500;
    }
    .ant-btn {
      height: unset;
    }
  }
`;

const SelectedContainer = styled.div`
  .ant-card {
    border: unset;
    .ant-card-head {
      padding: 0;
    }
    .ant-card-body {
      padding: 0;
    }
  }
`;

const ConfirmMessage = styled.div`
  color: black;
  font-weight: 500;
  margin-top: 1em;
  text-align: center;
`;

const StyledModal = styled(Modal)`
  .ant-modal-body {
    padding: 0;
    .confirm-current-location-card > .ant-card-head {
      display: none;
    }
  }
`;
