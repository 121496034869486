import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { SqContact } from "react-square-payment-form/lib/components/models";
import { TipData } from "../types";

interface CheckoutInitialSliceState {
  errorMessages?: string[];
  billingContactInfo?: SqContact;
  editingBillingContactInfo: boolean;
  requestedPickupTime?: string;
  idempotencyKey?: string;
  tip?: TipData;
  /** Unix timestamp of confirmation */
  confirmedLocation?: number;
  showReconfirmationLocationModal?: boolean;
  hasUserConsent?: boolean;
}

const initialState: CheckoutInitialSliceState = {
  editingBillingContactInfo: true,
};

const checkout = createSlice({
  name: "checkout",
  initialState,
  reducers: {
    reset(state) {
      state.errorMessages = undefined;
      state.billingContactInfo = undefined;
      state.editingBillingContactInfo = true;
      state.requestedPickupTime = undefined;
      state.tip = undefined;
      state.confirmedLocation = undefined;
      state.showReconfirmationLocationModal = undefined;
    },
    setErrorMessages(state, action: PayloadAction<string[]>) {
      state.errorMessages = action.payload;
    },
    addErrorMessage(state, action: PayloadAction<string>) {
      state.errorMessages = state.errorMessages?.length ? [...state.errorMessages, action.payload] : [action.payload]
    },
    updateBillingContactInfo(
      state,
      action: PayloadAction<SqContact | undefined>
    ) {
      state.billingContactInfo = action.payload;
    },
    updateEditingBillingContactInfo(
      state,
      action: PayloadAction<{ isEditing: boolean }>
    ) {
      state.editingBillingContactInfo = action.payload.isEditing;
    },
    setRequestedPickupTime(state, action: PayloadAction<string | undefined>) {
      state.requestedPickupTime = action.payload;
    },
    updateIdempotencyKey(state, action: PayloadAction<string>) {
      state.idempotencyKey = action.payload;
    },
    updateTip(state, action: PayloadAction<TipData>) {
      state.tip = action.payload;
    },
    editTip(state, action: PayloadAction<boolean>) {
      if (state.tip) {
        state.tip.beingEdited = action.payload;
      }
    },
    /**
     * Additionally, conditionally sets showReconfirmationLocationModal to false
     */
    setConfirmedLocation(state, action: PayloadAction<number | undefined>) {
      state.confirmedLocation = action.payload;
      if (action.payload !== undefined) {
        state.showReconfirmationLocationModal = false;
      }
    },
    setShowReconfirmationLocationModal(state, action: PayloadAction<boolean>) {
      state.showReconfirmationLocationModal = action.payload;
    },
    setHasUserConsent(state, action: PayloadAction<boolean>) {
      state.hasUserConsent = action.payload;
    },
  },
});

export default checkout;
