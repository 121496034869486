import { ApolloClient, createHttpLink, InMemoryCache } from '@apollo/client';
import { setContext } from '@apollo/client/link/context';
import store from "./store"

const httpLink = createHttpLink({
  uri: process.env.REACT_APP_JOO_API_GRAPHQL_ENDPOINT,
});

const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const {user: {jwt}} = store.getState();
  // return the headers to the context so httpLink can read them
  return jwt ? {
    headers: {
      ...headers,
      authorization: `Bearer ${jwt}`,
    }
  } : headers
});

const client = new ApolloClient({
  link: authLink.concat(httpLink),
  cache: new InMemoryCache()
});


export default client;