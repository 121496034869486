// import { Card as SquareCard, Payments } from "@square/web-payments-sdk-types";
import { Button, Card, Empty, Modal, Spin } from "antd";
import React, { FC, useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import styled from "styled-components";
import { IS_SQUARE_SANDBOX_MODE, SQUARE_APPLICATION_ID } from "../../constants";
import { RootState } from "../../ducks";
import checkout from "../../ducks/checkout";
import { FinalCostReturnValue } from "../../hooks/useCalculateFinalCost";
import usePaymentForm from "../../hooks/usePaymentForm";
import Logo from "../logo";

type SquareCard = any;
type Payments = any;

export const PaymentForm: FC<{
  finalCostReturnValue: FinalCostReturnValue;
}> = ({ finalCostReturnValue }) => {
  const dispatch = useDispatch();

  const [squareLoaded, setSquareLoaded] = useState(false);
  const [card, setCard] = useState<SquareCard>();
  const [isGeneratingToken, setIsGeneratingToken] = useState<boolean>(false);

  const billingContactInfo = useSelector(
    (state: RootState) => state.checkout.billingContactInfo
  );

  const {
    cardNonceResponseReceived: submitPaymentToServer,
    // createVerificationDetails,
    // createPaymentRequest, // For digital wallets
    finalCostLoading,
    paymentRequestLoading,
    errorMessages,
    locationSquareId,
    viewable,
    prettyTotal,
  } = usePaymentForm(finalCostReturnValue);

  // Load Square script from CDN
  useEffect(() => {
    if (!viewable) {
      return;
    }
    const id = "square-web-payments-sdk";

    // Check if it already is loaded and stop and report that it has been reported if so
    if (document.getElementById(id)) {
      setSquareLoaded(true);
      return;
    }

    // Load the script
    const scriptElm = document.createElement("script");
    scriptElm.type = "text/javascript";
    scriptElm.src = `https://${
      IS_SQUARE_SANDBOX_MODE ? "sandbox." : ""
    }web.squarecdn.com/v1/square.js`;
    scriptElm.id = id;

    // Report that the script tag has loaded on load
    scriptElm.addEventListener("load", () => {
      setSquareLoaded(true);
    });

    // Add it to the DOM
    document.head.appendChild(scriptElm);
  }, [viewable]);

  const initializeCard = useCallback(
    async (payments: Payments) => {
      const card = await payments.card({
        postalCode: billingContactInfo?.postalCode,
      });
      await card.attach("#card-container");

      return card;
    },
    [billingContactInfo]
  );

  const loadCardForm = useCallback(async () => {
    if (!window.Square) {
      throw new Error("Square.js failed to load properly");
    }

    let payments: Payments;
    try {
      payments = window.Square.payments(
        SQUARE_APPLICATION_ID,
        locationSquareId
      );
    } catch {
      console.error("missing app credentials");
      dispatch(checkout.actions.addErrorMessage("Issue creating payment form"));
      return;
    }

    let card: SquareCard;
    try {
      card = await initializeCard(payments);
      setCard(card);
    } catch (e) {
      console.error("Initializing Card failed", e);
      dispatch(
        checkout.actions.addErrorMessage("Issue creating card payment form")
      );
      return;
    }
  }, [locationSquareId, dispatch, initializeCard]);

  // Load the card form when the from element is in view (so Square Web Payments can attach to element)
  useEffect(() => {
    if (viewable && squareLoaded && !finalCostLoading) {
      // Reset errors
      dispatch(checkout.actions.setErrorMessages([]));
      // Load card form
      loadCardForm();
    }
  }, [viewable, squareLoaded, finalCostLoading, dispatch, loadCardForm]);

  /**
   * Handle payment method submission
   * @param {React.MouseEvent<HTMLElement, MouseEvent>} event
   * @param {SquareCard} paymentMethod
   */
  async function handlePaymentMethodSubmission(
    event: React.MouseEvent<HTMLElement, MouseEvent>,
    paymentMethod: SquareCard
  ) {
    event.preventDefault();

    // disable the submit button as we await tokenization and make a payment request.
    setIsGeneratingToken(true);

    // Reset errors on submission
    dispatch(checkout.actions.setErrorMessages([]));

    try {
      const token = await tokenize(paymentMethod);

      // Submit payment to server
      submitPaymentToServer(null, token);
    } catch (e) {
      console.error(e);
      dispatch(checkout.actions.addErrorMessage("Issue creating payment"));
    } finally {
      setIsGeneratingToken(false);
    }
  }

  if (!viewable) {
    return null;
  }

  return (
    <Card title="Payment" loading={finalCostLoading}>
      <form style={{ position: "relative" }}>
        <div style={{ position: "relative", minHeight: "94px" }}>
          {!card && (
            <div
              style={{
                position: "absolute",
                top: 0,
                right: 0,
                width: "100%",
                display: "flex",
                justifyContent: "center",
              }}
            >
              <Spin size="large" />
            </div>
          )}
          <div
            id="card-container"
            style={{
              width: "100%",
              visibility: card ? "visible" : "hidden",
            }}
          ></div>
        </div>
        <Button
          loading={isGeneratingToken || paymentRequestLoading}
          disabled={isGeneratingToken || paymentRequestLoading}
          block
          shape="round"
          type="primary"
          size="large"
          onClick={
            card
              ? (event) => handlePaymentMethodSubmission(event, card)
              : undefined
          }
        >
          {`Pay ${prettyTotal}`}
        </Button>
      </form>
      {errorMessages?.length ? (
        <ul style={{ marginTop: "1rem" }}>
          {errorMessages.map((error, index) => {
            return <li key={index}>{error}</li>;
          })}
        </ul>
      ) : null}

      <Modal
        visible={isGeneratingToken || paymentRequestLoading}
        footer={null}
        closable={false}
      >
        <Empty
          style={{ padding: "2em" }}
          imageStyle={{ height: "400px" }}
          image={<Logo width="400px" />}
          description={<Spin size="large" />}
        />
        <Center>Processing payment...</Center>
      </Modal>
    </Card>
  );
};

const Center = styled.div`
  text-align: center;
`;

/**
 * Tokenize payment info for Square
 * @param {SquareCard} paymentMethod
 * @returns {string}
 * @throws {Error}
 */
async function tokenize(paymentMethod: SquareCard): Promise<string> {
  const tokenResult = await paymentMethod.tokenize();
  if (tokenResult.status === "OK" && tokenResult.token) {
    return tokenResult.token;
  } else {
    let errorMessage = `Tokenization failed with status: ${tokenResult.status}`;
    if (tokenResult.errors) {
      errorMessage += ` and errors: ${JSON.stringify(tokenResult.errors)}`;
    }

    throw new Error(errorMessage);
  }
}
