import React, { FC, ReactElement } from "react";
import { PageHeader, Affix, Button, Badge } from "antd";
import styled from "styled-components";
import { ShoppingOutlined } from "@ant-design/icons";
import { useDispatch } from "react-redux";
import ui from "../ducks/ui";
import logo from "../logo.png";
import { Link, useHistory } from "react-router-dom";
import {
  MENU_PAGE_PATH,
  LOCATIONS_PAGE_PATH,
  ACCOUNT_PAGE_PATH,
} from "../router";
import { ButtonProps } from "antd/lib/button";
import useCart from "../hooks/useCart";

const iconStyle = {
  style: { fontSize: "24px", color: "#ffeb19" },
};

const topButtonProps: ButtonProps = {
  type: "text",
  shape: "round",
};

const Header: FC<{
  actionButtons?: ReactElement[];
  floatContent?: boolean;
}> = ({ actionButtons, children, floatContent = true }) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { cartItemCount } = useCart();

  const handleCartButton = () => {
    dispatch(ui.actions.toggleCart());

    if (history.location.pathname !== MENU_PAGE_PATH) {
      history.push(MENU_PAGE_PATH);
    }
  };

  const defaultTopMenuItems = [
    <Link key="MENU_PAGE_PATH" to={MENU_PAGE_PATH}>
      <TopMenuItem {...topButtonProps}>Menu</TopMenuItem>
    </Link>,
    <Link key="LOCATIONS_PAGE_PATH" to={LOCATIONS_PAGE_PATH}>
      <TopMenuItem {...topButtonProps}>Locations</TopMenuItem>
    </Link>,
    <Link key="ACCOUNT_PAGE_PATH" to={ACCOUNT_PAGE_PATH}>
      <TopMenuItem {...topButtonProps}>Account</TopMenuItem>
    </Link>,
    <Badge key="cart_badge" count={cartItemCount} offset={[0, 4]}>
      <Button
        key="cart"
        title="Show order"
        type="text"
        size="large"
        icon={<ShoppingOutlined {...iconStyle} />}
        onClick={handleCartButton}
      />
    </Badge>,
  ];

  const topMenuItems = actionButtons ? actionButtons : defaultTopMenuItems;

  return (
    <HeaderContainer
      title={
        <Link to={MENU_PAGE_PATH}>
          <img
            src={logo}
            alt="Jay Bird's Chicken"
            style={{
              width: "200px",
              marginTop: "-2em",
              marginBottom: "-2em",
            }}
          />
        </Link>
      }
      extra={<TopMenu>{topMenuItems}</TopMenu>}
    >
      {floatContent ? <Affix>{children}</Affix> : children}
    </HeaderContainer>
  );
};

const HeaderContainer = styled(PageHeader)`
  width: 100%;
  background-color: rgb(0 0 0);
  padding: 16px 0 0;

  &.ant-page-header-compact .ant-page-header-heading {
    justify-content: center;
  }

  .ant-page-header-heading {
    padding: 0 24px;
  }
  .ant-page-header-content {
    padding: 12px 24px 0;
  }

  @media (max-width: 550px) {
    .ant-page-header-content {
      padding: 12px 0 0;
    }
  }
`;

const TopMenu = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  flex-wrap: wrap;
  justify-content: center;
`;

const TopMenuItem = styled(Button)`
  color: white;
  &:hover {
    color: #b9b9b9;
  }
`;

export default Header;
