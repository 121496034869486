import { configureStore, getDefaultMiddleware } from "@reduxjs/toolkit";
import rootReducer from "./ducks";
import { loadState, saveState } from "./util/persist-state";
import _ from "lodash";

const store = configureStore({
  reducer: rootReducer,
  preloadedState: loadState(),
  middleware: [...getDefaultMiddleware()],
});

store.subscribe(
  _.debounce(() => {
    const currentState = store.getState();
    const { location } = currentState;

    saveState({
      ...currentState,
      ui: undefined,
      //catalog: undefined,
      checkout: undefined,
      user: undefined,
      location: {
        ...location,
        /*  data: {
          location: {},
        }, */
        error: undefined,
        //loading: undefined,
      },
    });
  }, 1000)
);

export default store;
