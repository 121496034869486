import { List } from "antd";
import styled from "styled-components";

export const StyledList = styled(List)`
  height: 100%;
  width: 100%;
  flex-shrink: 0;
  display: flex;
  flex-direction: column;

  h2 {
    text-align: center;
  }

  .ant-spin-nested-loading {
    height: 100%;
    overflow: scroll;
  }

  @media (min-width: 900px) {
    width: 450px;
    max-width: 100%;
  }
`;

export const Layout = styled.div`
  min-height: 100vh;
`;
