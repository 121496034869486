import { gql } from "@apollo/client";
import {
  ORDER_FIELDS_FRAGMENT,
  LOCATION_FIELDS_FRAGMENT,
} from "./my-fragments";

export const FETCH_CATALOG = gql`
  query FETCH_CATALOG {
    catalogCategories(where: { available_on_joo: true }) {
      id
      name
      ordinal
      square_id
      items(where: { available_on_joo: true }) {
        id
      }
    }

    catalogItems(where: { available_on_joo: true }) {
      id
      name
      square_id
      description
      meta {
        image {
          url
        }
        present_at_all_locations
        present_at_location_ids
        absent_at_location_ids
      }
      modifier_list_info {
        id
        modifier_list_id
        enabled
        max_selected_modifiers
        min_selected_modifiers
        modifier_overrides {
          id
          on_by_default
          modifier_id
        }
        modifier_list {
          id
          square_id
        }
      }
      taxes {
        id
      }
    }

    catalogItemVariations {
      id
      square_id
      item_id
      name
      price_money {
        amount
        currency
      }
      pricing_type
      ordinal
    }

    catalogModifierLists {
      id
      square_id
      ordinal
      selection_type
      name
      modifiers {
        id
        square_id
      }
    }

    catalogModifiers {
      id
      square_id
      name
      ordinal
      modifier_list_id
      price_money {
        amount
        currency
      }
    }

    catalogTaxes {
      id
      square_id
      enabled
      percentage
      name
      calculation_phase
      inclusion_type
      applies_to_custom_amounts
    }
  }
`;

export const FETCH_LOCATIONS = gql`
  query FETCH_LOCATIONS {
    locations {
      ...locationFields
    }
  }
  ${LOCATION_FIELDS_FRAGMENT}
`;

export const LOOKUP_LOCATIONS_WITH_SQUARE_ID = gql`
  query lookupLocationWithSquareId($square_id: String!) {
    locations(where: { square_id: $square_id }) {
      ...locationFields
    }
  }
  ${LOCATION_FIELDS_FRAGMENT}
`;

export const LOOKUP_ORDER = gql`
  query lookupOrder($id: ID!) {
    order(id: $id) {
      ...orderFields
    }
  }
  ${ORDER_FIELDS_FRAGMENT}
`;

export const LOCATION_AVAILABILITY = gql`
  query locationAvailability($locationId: String!) {
    locationAvailability(locationId: $locationId) {
      available
      timeSlot
    }
  }
`;
