import { useSelector } from "react-redux";
import { RootState } from "../ducks";
import useCurrentLocation from "./useCurrentLocation";

const useCheckoutStatus = () => {
  const lineItems = useSelector((state: RootState) => state.cart.lineItems);
  const { current } = useCurrentLocation();
  const { contactInfo, editing, loggedIn, isGuest } = useSelector(
    (state: RootState) => state.user
  );
  const { editingBillingContactInfo, billingContactInfo, requestedPickupTime, confirmedLocation, hasUserConsent } = useSelector(
    (state: RootState) => state.checkout
  );
  return {
    hasLineItems: lineItems.length > 0,
    hasSelectedLocation: !!current,
    hasUserContactInfo: !!contactInfo,
    hasSelectedAccessMethod: (loggedIn && !editing) || isGuest,
    hasValidBillingContactInfo:
      !editingBillingContactInfo && !!billingContactInfo,
      hasPickedTime: !!requestedPickupTime,
    lineItems,
    confirmedLocation,
    hasUserConsent,
    currentLocation: current
  };
};

export default useCheckoutStatus;
