import React, { FC, useEffect } from "react";
import { Empty } from "antd";
import logo from "../logo.svg";
import { Link } from "react-router-dom";
import { REFRESH_PAGE_PATH } from "../router";
import styled from "styled-components";

const Error: FC<{ error: any; description?: string }> = ({
  description,
  error,
}) => {
  const defaultDescription = (
    <p>
      There was a problem connecting with the server. Please refresh and try
      again. If the problem persists, please restart using{" "}
      <StyledLink to={REFRESH_PAGE_PATH}>this link</StyledLink> or contact
      customer support.
    </p>
  );

  useEffect(() => {
    if (error) console.error(error);
  }, [error]);

  return (
    <Empty
      image={logo}
      description={description ? description : defaultDescription}
    />
  );
};

export default Error;

const StyledLink = styled(Link)`
  color: black;
  text-decoration: underline;
`;
